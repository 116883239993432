import React from "react";
import styled from "styled-components";
import TextField from "../atoms/texts/text-field"
import PhoneIcon from "../../images/icons/phone.inline.svg";

const Telephone = ({phone}) => (
  <Wrapper>
    <PhoneIcon />
    <TextField bold style={{fontSize: "16px", marginLeft: "16px"}}>{phone}</TextField>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const StyledTextField = styled(TextField)`
  font-size: 16px;
`

Telephone.defaultProps = {
  phone: "+48 609 261 774"
};

export default Telephone;
