import React from "react";
import styled from "styled-components";
import InternalLinkField from "../../atoms/texts/link"

const MenuLinks = () => (
  <Wrapper>
      <InternalLinkField title={`Services`}/>
      <InternalLinkField title={`About`}/>
      <InternalLinkField title={`Products`}/>
      <InternalLinkField title={`Contact`}/>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export default MenuLinks;
