import React from "react";
import {Container, Col, Row} from "react-bootstrap"
import styled from 'styled-components';
import {Link} from "gatsby"
import ParanormalLogo from "../../images/paranormal-logo.inline.svg";

const Footer = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col md={4}>
          <FooterLinks>
              <li>
                <Link to={`/`}>Obowiązek informacyjny</Link>
              </li>
              <li>
                <Link to={`/`}>Regulamin strony</Link>
              </li>
          </FooterLinks>
        </Col>
        <Col md={3} className={`ml-auto`}>
          <Coded>
            <span>coded by</span> <a href={`https://paranormal.studio`} target={`_BLANK`}><ParanormalLogo /></a>
          </Coded>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

const Wrapper = styled.footer`
  background-color: white;
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding-top: 26px;
  padding-bottom: 26px;
  margin-top: 100px;
`;

const FooterLinks = styled.ul`
   display: flex;
   align-items: center;
   justify-content: flex-start;
   padding: 0;
   margin: 0;
   
   li{
    list-style-type: none;
    margin-right: 30px;
    
    a{
      font-family: Open Sans, sans-serif;
      font-size: 12px;
      color: #252525;
    }
   }
`;

const Coded = styled.div`
      font-family: Open Sans, sans-serif;
      font-size: 12px;
      color: #B9B9B9;
      display: flex;
      align-items: center;
      
      span{
        margin-top: 3px;
      }
      
      a{
        margin-left: 5px;
      }
`;

export default Footer;
