import React from "react"
import styled from "styled-components";

const TextField = ({children, bold, style}) => (
  <Text bold={bold} style={style}>
    {children}
  </Text>
);

const Text = styled.div`
  ${props => props.bold && 
    `font-family: Gilroy, sans-serif; font-weight: bold;`
  }
`;

export default TextField;
