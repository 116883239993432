import React from "react"
import styled from "styled-components";
import { Link } from "gatsby"

const InternalLinkField = ({to, title, rel, className}) => (
    <StyledLink to={to} rel={rel} className={className}>{title}</StyledLink>
);

const StyledLink = styled(Link)`
  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: #252525;
`;

InternalLinkField.defaultProps = {
  to: "/",
  title: "test",
  rel: "dofollow"
}

export default InternalLinkField;
