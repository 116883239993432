import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image";
import styled from "styled-components"
import TextField from "./texts/text-field"

const LogoField = ({title}) => (
  <StaticQuery
    query={graphql`
      {
        file(name: {eq: "logo"}){
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        } 
      }
    `}
    render={data =>(
      <Wrapper>
        <Logo fluid={data.file.childImageSharp.fluid} />
      </Wrapper>
    )}
  />
);

const Logo = styled(Img)`
  width: 170px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 40px;
`

export default LogoField;
