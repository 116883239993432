import React, {useState} from "react";
import styled from "styled-components";
import LogoField from "../../atoms/logo"
import MenuLinks from "../../molecules/header/menu-links"
import Telephone from "../../molecules/telephone"
import {Container, Row, Col} from "react-bootstrap"
import InternalLinkField from "../../atoms/texts/link"

const Header = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);

    return (
        <Wrapper>
            <Container>
                <nav className="navbar navbar-expand-lg navbar-light">
                    <LogoField/>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" onClick={() => setNavbarOpen(!navbarOpen)}
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className={navbarOpen ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'} id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active">
                                <InternalLinkField title={`Home`} className={`nav-link`} to={`/`}/>
                            </li>
                            <li className="nav-item">
                                <InternalLinkField title={`Usługi`} className={`nav-link`} to={`/#services`}/>
                            </li>
                            <li className="nav-item">
                                <InternalLinkField title={`O nas`} className={`nav-link`} to={`/#team`}/>
                            </li>
                            <li className="nav-item">
                                <InternalLinkField title={`Samochody`} className={`nav-link`} to={`/#cars`}/>
                            </li>
                            <li className="nav-item">
                                <InternalLinkField title={`Kontakt`} className={`nav-link`} to={`/#contact`}/>
                            </li>
                        </ul>
                        <div className={`ml-md-auto`}>
                            <Telephone/>
                        </div>
                    </div>
                </nav>
            </Container>
        </Wrapper>
    )
};

const Wrapper = styled.div`
  padding-top: 26px;
  padding-bottom: 25px;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  background: rgb(255,255,255);
  
`;

export default Header;
